:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}

body {
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}
body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

main {
  margin-left: 5rem;
  padding: 1rem;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  padding-top: 10px;
  color: white;
}

.App-header-login {
  max-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(2px + 2vmin);
  color: white;
}

.loginuser {
  display: flex;
  font-size: calc(2px + 2vmin);
}
.navlinks {
  color: rgb(27, 27, 27);
}

.TopRow {
  margin-top: 10px;
}

.SudokBtn {
  height: 50px;
  width: 50px;
  color: rgb(105, 107, 102);
  font-size: 16px;
  margin-right: 2px;
  background-color: white;
  border: 1px solid rgb(200, 216, 200);
  vertical-align: bottom;
}

.SudokBtn:hover {
  border: 2px solid green;
  color: green;
}

.SudokuDisBtn {
  height: 50px;
  width: 50px;
  color: rgb(134, 134, 132);
  font-size: 16px;
  margin-right: 2px;
  background-color: rgb(233, 233, 233);
  border: 1px solid rgb(158, 161, 158);
  vertical-align: bottom;
}

.modal-dialog {
  max-width: 300px;
}

.inputBtn {
  height: 35px;
  width: 45px;
  color: rgb(44, 81, 148);
  font-size: 15px;
  margin-right: 1px;
  background-color: rgb(235, 235, 235);
  border: none;
  vertical-align: bottom;
}

.inputBtn:hover {
  color: rgb(27, 50, 92);
  font-size: 14px;
  background-color: rgb(255, 255, 255);
}

.TopRow p {
  margin: 2px;
}

.inarow {
  display: block;
  margin: 2px;
}

.renderSq {
  display: inline;
}

.downbtnrow {
  justify-content: space-between;
  padding-top: 10px;
}

.rpad {
  margin-right: 6px;
  border-right: 1px solid darkslategrey;
}

.umarg {
  border-top: 1px solid darkslategrey;
}
.lmarg {
  border-left: 1px solid darkslategrey;
}
.bpad {
  margin-bottom: 4px;
  border-bottom: 1px solid darkslategrey;
}

.menubtn1 {
  margin-right: 10px;
}

.logbtn {
  margin-right: 10px;
  font-size: 10px;
  max-width: 80px;
  max-height: 70px;
}

.MenuBtn {
  height: 30px;
  width: 70px;
  color: rgb(74, 76, 77);
  font-size: 16px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: rgb(241, 226, 202);
  border: none;
  vertical-align: bottom;
}

.showgamescr {
  font-size: 12px;
}
.LoadBtn {
  height: 30px;
  width: 70px;
}

.menuLoadBtn {
  vertical-align: top;
  height: 25px;
  margin-bottom: 10px;
}

.MenuBtn:hover {
  border: 2px solid rgb(241, 181, 52);
  color: rgb(83, 39, 3);
}

.RedBtn {
  background-color: lightcoral;
}

.SelectOption {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  min-width: 40px;
}

.link-text {
  display: none;
  margin-left: 1rem;
}

.primaryModal {
  width: 10px;
}

/*
.rowbuttons{
  display: none;
}
*/

@media all and (min-width: 321px) and (max-width: 480px) {
  /* put your css styles in here */
  .SudokBtn {
    height: 32px;
    width: 32px;
    color: rgb(81, 82, 81);
    font-size: 12px;
    margin-right: 0.5px;
    background-color: white;
    border: 0.5px solid rgb(200, 216, 200);
    vertical-align: bottom;
  }

  .sudokubtncolor {
    background-color: rgb(187, 212, 241);
  }

  .SudokuDisBtn {
    height: 32px;
    width: 32px;
    color: rgb(105, 105, 105);
    font-size: 12px;
    margin-right: 0.5px;
    background-color: rgb(233, 233, 233);
    border: 0.5px solid rgb(158, 161, 158);
    vertical-align: bottom;
  }

  main {
    margin-left: 3px;
    padding: 1px;
  }

  .MenuBtn {
    height: 25px;
    width: 30px;
    color: rgb(74, 76, 77);
    font-size: 13px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: rgb(241, 226, 202);
    border: none;
    vertical-align: bottom;
  }

  .modal-dialog {
    max-width: 200px;
  }

  .navlinks {
    display: none;
  }
}

.rpad {
  margin-right: 3px;
  border-right: 1px solid darkslategrey;
}

.umarg {
  border-top: 1px solid darkslategrey;
}

.lmarg {
  border-left: 1px solid darkslategrey;
}

.bpad {
  margin-bottom: 3px;
  border-bottom: 1px solid darkslategrey;
}

.RedBtn {
  background-color: lightcoral;
}

.menubtn1 {
  margin-right: 2px;
  margin-top: 5px;
  max-width: 60px;
}

.rowBtn {
  margin-top: 7px;
  margin-right: 2px;
  font-size: 12px;
}

.optionscr {
  display: none;
}

p {
  font-size: 8pt;
}
h4 {
  font-size: 10pt;
}
